import store from '@/store'
import { updateProjectDetails } from '@/services/projects'
import { successModal, errorModal } from '@/modalMessages'

const schema = [
  {
    type: 'textarea',
    name: 'notes',
    label: 'Commentaar'
  }
]

const actions = {
  async load (projectId) {
    try {
      // Project data is already loaded by the layout, no need to call API again.
      const project = store.getters['properties/getProjectById'](projectId)
      const { notes } = { ...project }
      return { notes }
    } catch (error) {
      errorModal('Fout bij het laden van notities, probeer het opnieuw.')
      throw error
    }
  },
  async submit (projectId, data) {
    try {
      const response = await updateProjectDetails(projectId, data)
      await store.dispatch('properties/loadProject', projectId)
      successModal('Notities succesvol opgeslagen')
      return response
    } catch (error) {
      errorModal('Kan notitie niet aanpassen gelieve opnieuw te proberen')
      throw error
    }
  }
}

export default {
  heading: '',
  schema,
  actions
}
